import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CriteriaButtonsContainer from 'generic/containers/CriteriaButtonsContainer';
import CriteriaContainer from 'generic/containers/CriteriaContainer';
import SearchHeaderSkeleton from 'generic/components/skeletons/SearchHeaderSkeleton';
import NbResultsStrip from 'generic/components/ui/NbResultsStrip';
import { prettyPrintNumber } from 'generic/utils/mathUtils';

const ResultsHeader = ({ nbResults, loading }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Box display={loading ? 'block' : 'none'} width="inherit">
        <SearchHeaderSkeleton />
      </Box>
      <Box
        display={loading ? 'none' : 'flex'}
        mb={1}
        flexGrow="1"
        alignItems="center"
        columnGap="5px"
        flexWrap="wrap"
      >
        <Box flexGrow="1" display="flex" flexWrap="wrap">
          <CriteriaButtonsContainer />
          <CriteriaContainer />
        </Box>
        <Box flexShrink="0" display="flex" flexGrow="1" justifyContent="right">
          {nbResults !== undefined && (
            <NbResultsStrip>
              {`${prettyPrintNumber(nbResults)} ${t('results.document', { count: nbResults })}`}
            </NbResultsStrip>
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

ResultsHeader.propTypes = {
  loading: PropTypes.bool,
  nbResults: PropTypes.number,
};

ResultsHeader.defaultProps = {
  loading: false,
  nbResults: undefined,
};

export default ResultsHeader;
